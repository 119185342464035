import React, { createContext } from 'react';
import { MatxLoading } from 'app/components';
import { getSettings, get_phone_codes } from 'infra/services/api';
import { useSnackbar } from 'notistack';
import { useCreate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { setSettings } from 'core/redux/actions/generalDataActions';

const SettingsContext = createContext({
    createSetting: () => {},
    set_phone_codes: () => {},
    refetch: () => {},
    setOpen: () => {},
    phone_codes: [],
    data: null,
    isLoading: false,
    open: false,
});

export const AccountSettingsProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [create, { isLoading: submitLoading }] = useCreate();
    const [isLoading, setisLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [data, setdata] = React.useState(null);
    const account_id = localStorage.getItem('account_id');
    const [phone_codes, set_phone_codes] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const _getSettings = async () => {
            setisLoading(true);
            const response = await getSettings(account_id);
            if (response) {
                formatData(response);
                setisLoading(false);
            } else {
                setisLoading(false);
            }
        };
        _getSettings();
    }, []);

    React.useEffect(() => {
        if (phone_codes.length === 0) {
            const _get_phone_codes = async () => {
                const response = await get_phone_codes();
                set_phone_codes(response);
            };
            _get_phone_codes();
        }
    }, []);

    const createSetting = async (post_data) => {
        await create(
            'settings',
            {
                data: post_data,
            },
            { onSettled: onSuccess },
            { onError: onError }
        );
    };

    const onSuccess = () => {
        enqueueSnackbar(`Settings saved`, {
            variant: 'success',
        });
        setOpen(false);
        refetch();
    };
    const onError = (error) => {
        setOpen(false);
        enqueueSnackbar(`${error.message}`, { variant: 'error' });
    };

    //custom for remove
    const removeMessage = async (post_data) => {
        await create(
            'settings',
            {
                data: post_data,
            },
            { onSettled: successRemove },
            { onError: onError }
        );
    };
    const successRemove = () => {
        enqueueSnackbar(`Successfully removed site wide message`, {
            variant: 'success',
        });
        setOpen(false);
        refetch();
    };

    const refetch = async () => {
        const response = await getSettings(account_id);
        formatData(response);
    };

    const formatData = async (settings_data) => {
        const { provider_settings } = settings_data;
        if (Array.isArray(provider_settings)) {
            const json_data = {};
            provider_settings.forEach((element) => {
                let value = '';
                if (arrays.indexOf(element.setting) !== -1) {
                    value = JSON.parse(element.value);
                } else if (switch_settings.indexOf(element.setting) !== -1) {
                    value = element.value == null ? false : element.value;
                } else {
                    value = element.value;
                }

                json_data[element.setting] = value;
            });

            // phone code
            const response = await get_phone_codes();
            const filteredData = response.map((val) => val.code);
            
            setdata({ ...define_settings, ...json_data, phone_codes: filteredData });
            dispatch(setSettings({ ...define_settings, ...json_data,phone_codes: filteredData }));
        }
    };

    if (isLoading) {
        return <MatxLoading />;
    }
    return (
        <SettingsContext.Provider
            value={{
                setOpen: setOpen,
                refetch: refetch,
                data: data,
                open: open,
                isLoading: submitLoading,
                phone_codes: phone_codes,
                set_phone_codes: set_phone_codes,
                createSetting: createSetting,
                removeMessage: removeMessage,
                onSuccess: onSuccess,
                onError: onError,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;

export const arrays = [
    'parcel_types',
    'dashboard_statuses_collected_and_undelivered',
    'dashboard_statuses_collected_and_undelivered',
    'dashboard_statuses_uncollected_and_undelivered',
    'exclude_features',
    'provider_tracking_prefixes',
    'permissions_additional_settings',
    'shipment_cancellation_reason',
    'carriers',
    'service_levels',
    'other_scans',
    'delivery_failed_attempt_reasons',
    'shipment_terms',
    'note_types',
    'rejection_reasons',
    'collection_failed_attempt_reasons',
    'delivery_failed_attempt_reasons',
    'integrations',
    'locker_auth_key',
];

export const switch_settings = [
    'enable_payment_intervention', //Rates and billings
    'insurance_declared_value_enabled',
    'show_bill_transactions',
    'payment_methods_payfast_enabled',
    'payment_methods_ozow_enabled',
    'payment_methods_peach_payment_enabled',
    'payment_methods_eft_enabled',
    'payment_methods_payfastTopup_enabled',
    'payment_methods_ozowTopup_enabled',
    'payment_methods_Custom_payment_enabled',
    'payment_methods_eftTopup_enabled',
    'driver_can_action_hub_statuses', //Driver app
    'driver_must_add_tracking_sticker_each_parcel',
    'driver_can_reject_parcels_shipments',
    'driver_can_cancel_parcels',
    'driver_can_cancel_shipments',
    'driver_must_scan_each_parcel',
    'driver_can_add_shipments',
    'driver_can_add_parcels',
    'driver_show_floor_check_option',
    'allow_push_notifications_weekend',
    'allow_push_notifications_public_holidays',
    'drivers_can_enter_onetime_pin',
    'drivers_can_send_onetime_pin',
    'drivers_can_use_fallback_option',
];

export const define_settings = {
    url: '',
    terms_and_condition_url: '',
    privacy_url: '',
    tracking_url: '',
    logo_url: '',
    work_hours_from: '',
    work_hours_to: '',
    support_email_address: '',
    support_contact_number: '',
    dashboard_stats_start_date: '',
    dashboard_uncollected_shipments_duration_days: '',
    dashboard_undelivered_shipments_duration_days: '',
    dashboard_unclosed_collection_requests_duration_days: '',
    dashboard_statuses_collected_and_undelivered: [],
    dashboard_statuses_uncollected_and_undelivered: [],
    exclude_features: [],
    map_max_node_count: '',
    provider_tracking_prefixes: [],
    message_settings_type: '',
    client_portal_site_wide_message_type: '',
    client_portal_site_wide_message: '',
    admin_site_wide_message_type: '',
    admin_site_wide_message: '',
    shipment_terms: [],
    billing_email_address: '',
    billing_contact_number: '',
    invoice_company_name: '',
    invoice_company_address: '',
    invoice_company_registration_no: '',
    invoice_company_vat_no: '',
    invoice_vat_percentage: '',
    invoice_quote_disclaimer: '',
    invoice_trigger_shipment_status: '',
    enable_payment_intervention: '',
    shipping_difference_minimum: '',
    insurance_declared_value_enabled: '',
    insurance_max_declared_value: '',
    show_bill_transactions: '',
    payment_methods_payfast_merchantID: '',
    payment_methods_payfast_merchantKey: '',
    payment_methods_payfast_passphrase: '',
    payment_methods_payfast_enabled: '',
    payment_methods_eft_details_bank: '',
    payment_methods_eft_details_branch_code: '',
    payment_methods_eft_details_account_type: '',
    payment_methods_eft_details_account_number: '',
    payment_methods_eft_enabled: '',
    payment_methods_payfastTopup_merchantID: '',
    payment_methods_payfastTopup_merchantKey: '',
    payment_methods_payfastTopup_passphrase: '',
    payment_methods_payfastTopup_topup_limit: '',
    payment_methods_payfastTopup_enabled: '',
    payment_methods_bobpayTopup_recipient_account: '',
    payment_methods_bobpayTopup_passphrase: '',
    payment_methods_bobpayTopup_topup_limit: '',
    payment_methods_ozow_siteCode: '',
    payment_methods_ozowTopup_siteCode: '',
    payment_methods_ozowTopup_topup_limit: '',
    payment_methods_peachTopup_merchantID: '',
    payment_methods_peachTopup_secretKey: '',
    payment_methods_peachTopup_limit: '',
    payment_methods_peach_payment_enabled: '',
    payment_methods_stitchTopup_enabled: '',
    payment_methods_stitchTopup_clientId: '',
    payment_methods_stitchTopup_clientSecret: '',
    payment_methods_stitchTopup_bankId: '',
    payment_methods_stitchTopup_accountNumber: '',
    payment_methods_stitchTopup_beneficiaryName: '',
    payment_methods_stitchTopup_limit: '',
    payment_methods_eftTopup_details_bank: '',
    payment_methods_eftTopup_details_branch_code: '',
    payment_methods_eftTopup_details_account_type: '',
    payment_methods_eftTopup_details_account_number: '',
    payment_methods_eftTopup_enabled: '',
    payment_import_start_date: '',
    payment_import_end_date: '',
    maximum_parcel_length: '',
    maximum_parcel_width: '',
    maximum_parcel_height: '',
    maximum_parcel_weight: '',
    parcel_types: [],
    note_types: [],
    permissions_additional_settings: [],
    shipment_cancellation_reason: [],
    carriers: [],
    service_levels: [],
    other_scans: [],
    driver_can_action_hub_statuses: '',
    driver_must_add_tracking_sticker_each_parcel: '',
    driver_can_reject_parcels_shipments: '',
    driver_can_cancel_parcels: '',
    driver_can_cancel_shipments: '',
    driver_must_scan_each_parcel: '',
    driver_can_add_shipments: '',
    driver_can_add_parcels: '',
    driver_show_floor_check_option: '',
    allow_push_notifications_weekend: '',
    allow_push_notifications_public_holidays: '',
    notification_schedule_from: '',
    notification_schedule_until: '',
    drivers_can_enter_onetime_pin: '',
    drivers_can_send_onetime_pin: '',
    drivers_can_use_fallback_option: '',
    driver_pod2_name_enabled: '',
    driver_pod2_mobile_number_enabled: '',
    driver_pod2_department_enabled: '',
    driver_pod2_description_enabled: '',
    driver_pod2_photos_enabled: '',
    driver_pod2_signature_enabled: '',
    driver_pod2_name_required: '',
    driver_pod2_mobile_number_required: '',
    driver_pod2_department_required: '',
    driver_pod2_description_required: '',
    driver_pod2_photos_required: '',
    driver_pod2_signature_required: '',
    rejection_reasons: [],
    collection_failed_attempt_reasons: [],
    delivery_failed_attempt_reasons: [],
    notification_email_address: '',
    notification_name: '',
    notification_footer: '',
    notify_collection_on_submitted_whatsapp: '',
    notify_delivery_on_collected_whatsapp: '',
    notify_collection_on_collected_whatsapp: '',
    notify_delivery_on_at_destination_hub_whatsapp: '',
    notify_collection_on_at_destination_hub_whatsapp: '',
    notify_delivery_on_out_for_delivery_whatsapp: '',
    notify_collection_on_out_for_delivery_whatsapp: '',
    notify_delivery_on_ready_for_pickup_whatsapp: '',
    notify_collection_on_ready_for_pickup_whatsapp: '',
    notify_delivery_on_delivery_failed_attempt_whatsapp: '',
    notify_collection_on_collection_failed_attempt_whatsapp: '',
    notify_collection_on_delivery_failed_attempt_whatsapp: '',
    notify_collection_on_delivered_whatsapp: '',
    notify_delivery_on_delivered_whatsapp: '',
    notify_collection_on_collection_exception_whatsapp: '',
    notify_collection_on_delivery_exception_whatsapp: '',
    notify_delivery_on_delivery_exception_whatsapp: '',
    notify_delivery_on_cancelled_whatsapp: '',
    notify_collection_on_cancelled_whatsapp: '',
    notify_collection_on_ready_for_pickup_sms: '',
    notify_delivery_on_ready_for_pickup_sms: '',
    notify_collection_on_ready_for_pickup_email: '',
    notify_delivery_on_ready_for_pickup_email: '',
    notify_collection_on_submitted_email: '',
    notify_collection_on_collected_email: '',
    notify_collection_on_at_destination_hub_email: '',
    notify_collection_on_out_for_delivery_email: '',
    notify_collection_on_collection_failed_attempt_email: '',
    notify_collection_on_delivery_failed_attempt_email: '',
    notify_collection_on_delivered_email: '',
    notify_collection_on_collection_exception_email: '',
    notify_collection_on_delivery_exception_email: '',
    notify_collection_on_cancelled_email: '',
    notify_collection_on_shipment_address_changed_email: '',
    notify_delivery_on_collected_email: '',
    notify_delivery_on_at_destination_hub_email: '',
    notify_delivery_on_out_for_delivery_email: '',
    notify_delivery_on_delivery_failed_attempt_email: '',
    notify_delivery_on_delivered_email: '',
    notify_delivery_on_delivery_exception_email: '',
    notify_delivery_on_cancelled_email: '',
    notify_collection_on_submitted_sms: '',
    notify_collection_on_collected_sms: '',
    notify_collection_on_at_destination_hub_sms: '',
    notify_collection_on_out_for_delivery_sms: '',
    notify_collection_on_collection_failed_attempt_sms: '',
    notify_collection_on_delivery_failed_attempt_sms: '',
    notify_collection_on_delivered_sms: '',
    notify_collection_on_collection_exception_sms: '',
    notify_collection_on_delivery_exception_sms: '',
    notify_collection_on_cancelled_sms: '',
    notify_collection_on_shipment_address_changed_sms: '',
    notify_delivery_on_collected_sms: '',
    notify_delivery_on_at_destination_hub_sms: '',
    notify_delivery_on_out_for_delivery_sms: '',
    notify_delivery_on_delivery_failed_attempt_sms: '',
    notify_delivery_on_delivered_sms: '',
    notify_delivery_on_delivery_exception_sms: '',
    notify_delivery_on_cancelled_sms: '',
    shipment_rescheduled_notification_email: '',
    shipment_cancelled_notification_email: '',
    notify_shipment_rescheduled_email: '',
    notify_shipment_rescheduled_sms: '',
    shorten_tracking_urls: '',
    integrations: [],
    locker_deposit_pending: '',
    locker_courier_deposited: '',
    kiosk_deposit_pending: '',
    kiosk_courier_deposited: '',
    notify_nps_sending_email: '',
    notify_nps_sending_sms: '',
    notify_nps_sending_whatsapp: '',
    notify_out_for_delivery_sl_webhook_sending_whatsapp: '',
    notify_nps_sending_whatsapp_threshold: '',
    notify_nps_sending_whatsapp_remaining: '',
    notify_nps_sending_whatsapp_enable_treshold:''
};
